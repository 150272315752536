<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-03-28 17:50:40
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-04-18 18:43:40
 * @FilePath: /mediatom-web/src/views/aggregate/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="aggregate-container fixed_container">
    <a-menu v-if="!otherPath.includes(current[0])" v-model="current" mode="horizontal" class="menu_group fixed_menu">
      <a-menu-item key="/aggregate/config">
        <router-link :to="{ name: 'config' }">瀑布流配置</router-link>
      </a-menu-item>
      <a-menu-item key="/aggregate/RtbConfig">
        <router-link :to="{ name: 'RtbConfig' }">程序化广告配置</router-link>
      </a-menu-item>
    </a-menu>
    <route-view></route-view>
  </div>
</template>
<script>
import { RouteView } from '@/layouts'
export default {
  name: 'Aggregate',
  components: {
    RouteView
  },
  data () {
    return {
      current: [],
      otherPath: [
        '/aggregate/advanced',
        '/aggregate/batchAddSource',
        '/aggregate/abtestdata',
        '/aggregate/recommendTemplate'
      ]
    }
  },
  watch: {
    $route: {
      handler (val) {
        this.current = [val.path]
      },
      immediate: true
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep.aggregate-container {
  // min-width: 1280px;
  background-color: #f7f7f7;
}
</style>
